<template>
  <v-container>
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div id="printableArea">
          <div class="card">
            <div class="card-body invoice-head">
              <div class="row">
                <div class="col-md-3 align-self-center">
                  <center><img src="@/assets/arm.png" height="100">
                  </center>
                </div>
                <div class="col-md-6 align-self-center">
                  <center>
                    <h2 style="color: rgb(22, 88, 165);" class="logo-lg logo-dark">United Republic of Tanzania</h2>
                    <h4 style="color: rgb(10, 125, 255);" class="logo-lg logo-dark">FLAGSHIP PROJECTS: WORKDONE AND BUDGET EXPENDITURE</h4></center>
                </div>
              </div><hr>
              <hr>

              <div class="row">
                <div class="col-lg-12">
                  <div class="table-responsive project-invoice">
                    <v-simple-table class="table table-bordered">
                      <thead class="thead-light">
                      <tr>
                        <th>Currency </th>
                        <th>Overall Budget Allocated</th>
                        <th>Gorvernment Budget / Funds</th>
                        <th>Foreign Budget / Funds / Load / Aid </th>
                        <th>Cumulative Expenditure From Commencement Todate</th>
                        <th>Cumulative Remain Amount to be Paid</th>
                      </tr>

                      </thead>
                      <tbody>
                      <tr>
                        <td>TZS</td>
                        <td>19,685,900,000,000</td>
                        <td>7,212,400,000,351</td>
                        <td>2,300,500,000,248</td>
                        <td>1,670,144,090,979</td>
                        <td>18,015,755,909,021</td>
                      </tr>
                      </tbody>
                    </v-simple-table>


                  </div>
                </div>
              </div>

            </div>
            <v-card class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="table-responsive project-invoice" style="overflow-x:auto;">
                    <v-simple-table class="table table-bordered mb-0">
                      <thead class="" style="font-size:11px;">
                      <tr>
                        <th rowspan="3">S/N </th>
                        <th rowspan="3">Project Name </th>
                        <th rowspan="3">Stage</th>
                        <th rowspan="3">Tender Name</th>
                        <th rowspan="3">Tender Number</th>
                        <th rowspan="10">No. of Components</th>
                        <th rowspan="3" style="width:15%;">Project  Description </th>
                        <th rowspan="3">Contract Sum (TZS/USD/EUR)</th>
                        <th rowspan="3" style="width:7%;">Cumulative Expenditure
                          From Commencement
                          Todate(TZS/USD/EUR)</th>
                        <th rowspan="3">Hand-over Date</th>
                        <th rowspan="3">Actual
                          Expenditure in
                          FY 2020/2021</th>
                        <th rowspan="3">Flag</th>
                        <th rowspan="3">Remarks</th>
                        <th colspan="2">Project Status</th>
                      </tr>

                      <tr>
                        <th colspan="2">Physical</th>
                        <th colspan="2">Financial</th>
                      </tr>
                      <tr>
                        <th>Plan</th>
                        <th>Actual</th>
                        <th>Plan</th>
                        <th>Actual</th>
                      </tr>
                      </thead>
                      <tbody style="font-size:10px;">
                      <tr>
                        <td>12</td>
                        <td>Innovation Center</td>
                        <td>
                          <strong style="color:rgb(13, 74, 144);">Ongoing . . .</strong>
                        </td>
                        <td>Innovation Center</td>
                        <td>Innovation Center091</td>
                        <td>2</td>
                        <td><p>The new  (Innovation Center), is intended to improve and provide better service than the old, inefficient&nbsp;<a href="https://en.wikipedia.org/wiki/Metre-gauge">metre-gauge</a>&nbsp;railway system</p>
                        </td>
                        <td>TZS:
                          7,000,000,000,000</td>
                        <td>

                          TZS:
                          1,670,087,090,900

                        </td>
                        <td>2022-09-21</td>
                        <td>TZS:
                          59,165,218,971</td>
                        <td><i class="fas fa-flag text- font-12 "></i></td>
                        <td>
                          <strong>Challenges</strong>

                          <li>None</li>

                          <li>Certificate Delay</li>

                          <li>None</li>

                          <hr>
                          <strong>Remarks</strong>

                          <li>Work Done successfully</li>

                          <li>MoF should increase</li>

                          <li>None</li>

                        </td>
                        <td>

                          0 %

                        </td>
                        <td>85.6%</td>
                        <td>

                          0 %

                        </td>
                        <td>23.86%</td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                </div>

              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "GeneralReport"
}
</script>

<style scoped>
/* table { border: 1px solid #000; border-collapse: collapse; } */
table th { padding: 5px 10px; border: 0.5px solid rgb(133, 133, 133); }
table td { padding: 5px 10px; border-left: 0.5px solid rgb(133, 133, 133); border-right: 0.5px solid rgb(133, 133, 133); }
th{
  text-align: center;
}

/*
Generic Styling, for Desktops/Laptops
*/
table {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */
tr:nth-of-type(odd) {
  background: rgb(255, 255, 255);
}
/* td {
    padding: 6px;
    border: 1px solid rgb(255, 255, 255);
    text-align: left;
} */
</style>
